import { Box, HStack, Select, Spinner, Text, VStack } from 'native-base'
import { useEffect, useState } from 'react'

import { Camera, NO_CAMERA } from '../../../../domain/models/interfaces/camera'
import { useCamerasContext } from '../../providers/CameraProvider'
import FaIcon from '../_shared/FaIcon'

const CameraPicker = ({ setError }) => {
  const { listCameras, selectCamera, selectedCamera } = useCamerasContext()
  const [cameras, setCameras] = useState<Camera[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const fetchCameras = async () => {
    setLoading(true)
    setError(undefined)

    try {
      const availableCameras = await listCameras()
      setCameras(availableCameras)
    } catch (error) {
      setCameras([NO_CAMERA])
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchCameras()
  }, [])

  const handleSelect = (selectedName: string) => {
    const selectedCamera = cameras.find(
      (camera) => camera.name === selectedName
    )
    if (selectedCamera) selectCamera(selectedCamera)
  }

  const spinner = (
    <HStack space={4} height={8} alignItems={'center'}>
      <Spinner />
      <Text>Loading cameras...</Text>
    </HStack>
  )

  const picker = (
    <VStack space={4}>
      <HStack alignItems="center" justifyContent="space-between">
        <Select
          selectedValue={selectedCamera?.name || ''}
          size="lg"
          accessibilityLabel="Select Camera"
          placeholder="Select Camera"
          onValueChange={handleSelect}>
          {cameras.map((cam) => (
            <Select.Item key={cam.name} label={cam.name} value={cam.name} />
          ))}
        </Select>
        <FaIcon name="rotate" onPress={() => fetchCameras()} />
      </HStack>
    </VStack>
  )

  return <Box>{loading ? spinner : picker}</Box>
}

export default CameraPicker
