import { createContext, ReactNode, useCallback, useContext } from 'react'
import useMeeting, { UseMeetingResult } from '../hooks/useMeeting'
import { useRoomContext } from './Room30Provider'

export interface UseMeetingContext extends UseMeetingResult {}

const MeetingContext = createContext<UseMeetingContext | undefined>(undefined)

export const useMeetingContext = (): UseMeetingContext =>
  useContext(MeetingContext)

interface MeetingProviderProps {
  children: ReactNode
}

export const MeetingProvider: React.FC<MeetingProviderProps> = ({
  children,
}) => {
  const { room } = useRoomContext()

  const meeting = useMeeting(room?.me.token)

  const context: UseMeetingContext = useCallback(() => meeting, [meeting])()

  return (
    <MeetingContext.Provider value={context}>
      {children}
    </MeetingContext.Provider>
  )
}
