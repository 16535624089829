import { useEffect, useState } from 'react'
import {
  connect,
  LocalAudioTrack,
  LocalVideoTrack,
  RemoteAudioTrack,
  RemoteTrack,
  RemoteVideoTrack,
} from 'twilio-video'

import { Camera, NO_CAMERA } from '../../../domain/models/interfaces/camera'
import {
  Microphone,
  NO_MICROPHONE,
} from '../../../domain/models/interfaces/microphone'
import { Audio, Meeting, UseMeeting, Video } from './useMeeting'

const useMeeting: UseMeeting = (token: string) => {
  const [meeting, setMeeting] = useState<Meeting>(null)
  const [error, setError] = useState<string>(null)

  const createMeeting: (token: string) => Promise<Meeting> = async (token) => {
    const room = await connect(token, { audio: false, video: false })
    let microphoneTrack: LocalAudioTrack
    let cameraTrack: LocalVideoTrack

    const meeting: Meeting = {
      room: room,
      setMicrophone: async (microphone: Microphone) => {
        if (microphoneTrack) {
          room.localParticipant.unpublishTrack(microphoneTrack)
          microphoneTrack.stop()
          microphoneTrack = null
        }

        if (microphone && microphone !== NO_MICROPHONE) {
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: { deviceId: microphone.object.deviceId },
          })
          const track = stream.getAudioTracks()[0]
          const audioTrack = new LocalAudioTrack(track)
          await room.localParticipant.publishTrack(audioTrack)
          microphoneTrack = audioTrack
        }
      },
      mute: () => {
        microphoneTrack?.disable()
      },
      unmute: () => {
        microphoneTrack?.enable()
      },
      setCamera: async (camera: Camera) => {
        if (cameraTrack) {
          room.localParticipant.unpublishTrack(cameraTrack)
          cameraTrack.stop()
          cameraTrack = null
        }

        if (camera && camera !== NO_CAMERA) {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { deviceId: camera.object.deviceId },
          })
          const track = stream.getVideoTracks()[0]
          const videoTrack = new LocalVideoTrack(track, {})
          await room.localParticipant.publishTrack(videoTrack)
          cameraTrack = videoTrack
        }
      },
      cameraOff: () => {
        cameraTrack?.disable()
      },
      cameraOn: () => {
        cameraTrack?.enable()
      },
      onNewAudio: (callback) => {
        room.on('trackSubscribed', (track: RemoteTrack) => {
          if (track.kind === 'audio') {
            callback(createAudio(track))
          }
        })
      },
      onNewVideo: (callback) => {
        room.on('trackSubscribed', (track: RemoteTrack) => {
          if (track.kind === 'video') {
            callback(createVideo(track))
          }
        })
      },
      onVideoRemoved: (callback) => {
        room.on('trackUnsubscribed', (track: RemoteTrack) => {
          if (track.kind === 'video') {
            callback(createVideo(track))
          }
        })
      },
      leave: () => {
        room.disconnect()
      },
    }

    return meeting
  }

  const createAudio: (track: RemoteAudioTrack) => Audio = (track) => ({
    play: () => track.attach(),
    stop: () => track.detach().forEach((el: HTMLMediaElement) => el.remove()),
  })

  const createVideo: (track: RemoteVideoTrack) => Video = (track) => ({
    play: (element: HTMLVideoElement) => track.attach(element),
    stop: () => track.detach().forEach((el: HTMLMediaElement) => el.remove()),
  })

  useEffect(() => {
    if (!token) return () => {}

    createMeeting(token)
      .then(setMeeting)
      .catch((error) => setError(error.message))

    return () => meeting?.leave()
  }, [token])

  return { meeting, error }
}
export default useMeeting
