import { Client } from '@stomp/stompjs'
import { useCallback, useEffect, useState } from 'react'
import { Room } from '../../../domain/models/Room'
import Configuration from '../../../domain/services/ConfigurationService'
import { useGetProfile } from '../components/profile/queries'
import { useCabanaApi } from '../providers/CabanaApiProvider'
import useAuthentication from './authentication/useAuthentication'

export type UseRoomResult = {
  room: Room | null
  error: string | null

  chooseAlias: (alias: string) => Promise<void>
  start: () => Promise<void>
  mute: () => Promise<void>
  unmute: () => Promise<void>
  cameraOff: () => Promise<void>
  cameraOn: () => Promise<void>
  join: () => Promise<void>
}

export interface UseRoom {
  (roomId: string): UseRoomResult
}

export const useRoom: UseRoom = (roomId) => {
  const [room, setRoom] = useState(null)
  const [error, setError] = useState(null)
  const { getAccessToken } = useAuthentication()
  const { RoomApiV2 } = useCabanaApi()
  const { userProfile } = useGetProfile(true)

  useEffect(() => {
    const setupClient = async () => {
      try {
        const accessToken = await getAccessToken()
        const wsClient = new Client({
          brokerURL: `${Configuration.CABANA_SERVICE_WEBSOCKET_URL}?access_token=${accessToken}`,
          onConnect: () => {
            wsClient.subscribe(`/user/room/${roomId}/error`, handleErrorMessage)
            wsClient.subscribe(`/room/${roomId}`, handleRoomUpdate)
          },
          onStompError: (frame) => {
            setError(`Broker error: ${frame.headers['message']}`)
          },
          onDisconnect: (frame) => {
            setError(`Disconnect error: ${frame.headers['message']}`)
          },
          onWebSocketClose: (event) => {
            setError(`Socket closed`)
          },
          onWebSocketError: (event) => {
            setError(`Socket error`)
          },
          reconnectDelay: 2000,
        })

        wsClient.activate()

        // Cleanup on component unmount
        return () => {
          wsClient.deactivate()
        }
      } catch (e) {
        setError(`Failed to initialize WebSocket: ${e.message}`)
      }
    }

    if (userProfile.id) setupClient()
  }, [roomId, userProfile])

  const handleRoomUpdate = useCallback(
    (message) => {
      try {
        const roomData: Room = JSON.parse(message.body)
        roomData.me = roomData.attendees.find(
          (attendee) => attendee.id === userProfile.id
        )
        setRoom(roomData)
      } catch (e) {
        setError(`Room update error: ${e.message}`)
      }
    },
    [userProfile]
  )

  const handleErrorMessage = useCallback((message) => {
    const errorData = message.body
    setError(errorData || 'An unknown error occurred')
  }, [])

  const chooseAlias = useCallback((alias: string) => RoomApiV2.chooseAlias(roomId, room.me.id, alias), [roomId, room?.me.id])
  const start = useCallback(() => RoomApiV2.start(roomId), [roomId])
  const mute = useCallback(() => RoomApiV2.mute(roomId, room?.me.id), [roomId, room?.me.id])
  const unmute = useCallback(() => RoomApiV2.unmute(roomId, room.me.id), [roomId, room?.me.id])
  const cameraOff = useCallback(() => RoomApiV2.cameraOff(roomId, room.me.id), [roomId, room?.me.id])
  const cameraOn = useCallback(() => RoomApiV2.cameraOn(roomId, room.me.id), [roomId, room?.me.id])
  const join = useCallback(() => RoomApiV2.join(roomId, room.me.id), [roomId, room?.me.id])

  return {
    room,
    error,
    chooseAlias,
    start,
    mute,
    unmute,
    cameraOff,
    cameraOn,
    join,
  }
}
