import {
  Microphone,
  NO_MICROPHONE,
} from '../../../domain/models/interfaces/microphone'
import { UseMicrophones } from './useMicrophones'

const requestPermissions: () => Promise<void> = async () => {
  await navigator.mediaDevices.getUserMedia({ audio: true })
}

const fetchMicrophones = async (): Promise<Microphone[]> => {
  const devices = await navigator.mediaDevices.enumerateDevices()
  const audioDevices = devices.filter((device) => device.kind === 'audioinput')
  const microphones = audioDevices.map((device) => ({
    name: device.label || 'Unnamed microphone',
    object: device,
  }))
  return microphones
}

export const useMicrophones: UseMicrophones = () => {
  const listMicrophones = async () => {
    await requestPermissions()
    const microphones = await fetchMicrophones()
    return [...microphones, NO_MICROPHONE]
  }

  return {
    listMicrophones,
  }
}

export default useMicrophones
