import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import {
  Container,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  VStack,
} from 'native-base'
import { useEffect, useRef, useState } from 'react'
import { Room } from '../../../domain/models/Room'
import CameraPicker from '../components/liveGroup/CameraPicker'
import MicrophonePicker from '../components/liveGroup/MicrophonePicker'
import secureScreen from '../hooks/authentication/secureScreen'
import useSpeakingDetection from '../hooks/useSpeakingDetection'
import { useCabanaApi } from '../providers/CabanaApiProvider'
import { CamerasProvider, useCamerasContext } from '../providers/CameraProvider'
import {
  MeetingProvider,
  useMeetingContext,
} from '../providers/MeetingProvider'
import {
  MicrophonesProvider,
  useMicrophonesContext,
} from '../providers/MicrophoneProvider'
import { RoomProvider, useRoomContext } from '../providers/Room30Provider'
import LoadingScreen from './LoadingScreen'
import { NO_MICROPHONE } from '../../../domain/models/interfaces/microphone'
import { NO_CAMERA } from '../../../domain/models/interfaces/camera'

const RoomTestScreen = () => {
  //hardcode the room
  const roomId = 'recpXXZwpy8m2IM0r'

  // auto register
  const { ActivityApi } = useCabanaApi()
  const [loading, setLoading] = useState<boolean>(true)
  useEffect(() => {
    ActivityApi.register({ id: roomId }).then(() => setLoading(false))
  }, [])

  if (loading) return <LoadingScreen />

  return (
    <RoomProvider roomId={roomId}>
      <MeetingProvider>
        <MicrophonesProvider>
          <CamerasProvider>
            <RoomTestComponent />
          </CamerasProvider>
        </MicrophonesProvider>
      </MeetingProvider>
    </RoomProvider>
  )
}

const RoomTestComponent = () => {
  const { room, start, mute, cameraOff } = useRoomContext()
  const { meeting } = useMeetingContext()
  const { selectedMicrophone } = useMicrophonesContext()
  const { selectedCamera } = useCamerasContext()

  // auto start
  useEffect(() => {
    if (room) start()
  }, [room?.id])

  // send the microphone to the meeting
  useEffect(() => {
    meeting?.setMicrophone(selectedMicrophone).then(() => {
      // mute the newly added microphone
      if(!room.me.microphone) meeting.mute()
    })
  }, [selectedMicrophone, meeting])

  // selecting "no microphone" should mute you
  useEffect(() => {
    if(room && selectedMicrophone === NO_MICROPHONE) mute()
  }, [selectedMicrophone, room, mute])

  // mute/unmute
  useEffect(() => {
    if (!room || !meeting) return
    else if (room.me.microphone) meeting.unmute()
    else meeting.mute()
  }, [room?.me.microphone, meeting])

  // send the camera to the meeting
  useEffect(() => {
    meeting?.setCamera(selectedCamera).then(() => {
      if (!room.me.camera) meeting.cameraOff()
    })
  }, [selectedCamera, meeting])

  // selecting "no camera" should turn your camera off
  useEffect(() => {
    if(room && selectedCamera === NO_CAMERA) cameraOff()
  }, [selectedCamera, room, cameraOff])

  // camera off/on
  useEffect(() => {
    if (!room || !meeting) return
    else if (room.me.camera) meeting.cameraOn()
    else meeting.cameraOff()
  }, [room?.me.camera, meeting])

  return (
    <Container padding={4} h={'full'}>
      <HStack space={4} h={'full'}>
        <VStack space={4}>
          <RoomData flex={1} />
          <MeetingData flex={1} />
        </VStack>
        <VStack space={4}>
          <MicrophoneStuff />
          <CameraStuff />
          <Controls />
          <ParticipantList />
        </VStack>
        <VStack>
          <Audio />
          <Videos />
        </VStack>
      </HStack>
    </Container>
  )
}

const Panel = ({ children, title, ...props }) => {
  return (
    <VStack
      space={2}
      outlineStyle={'solid'}
      borderRadius={'md'}
      padding={4}
      maxH={'full'}
      overflowY={'scroll'}
      {...props}>
      {title && <Heading>{title}</Heading>}
      {children}
    </VStack>
  )
}

const RoomData = ({ ...props }) => {
  const { room, error } = useRoomContext()

  const truncateToken = (token: string) =>
    token ? `${token.slice(0, 10)}...${token.slice(-5)}` : null

  const json = (room: Room): string => {
    const roomCopy: Room = JSON.parse(JSON.stringify(room))
    if (roomCopy) {
      roomCopy.attendees.forEach(
        (attendee) => (attendee.token = truncateToken(attendee.token))
      )
      roomCopy.me.token = truncateToken(roomCopy.me.token)
    }
    return JSON.stringify(roomCopy, null, 2)
  }

  return (
    <Panel title="Room Data" {...props}>
      <pre>{json(room)}</pre>
      {error && <Text color={'error.700'}>{error}</Text>}
    </Panel>
  )
}

const MeetingData = ({ ...props }) => {
  const { meeting, error } = useMeetingContext()
  const [json, setJson] = useState<string>(null)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setJson(JSON.stringify(meeting?.room, null, 2))
    }, 1000)
    return () => clearInterval(intervalId)
  }, [meeting])

  return (
    <Panel title="Meeting Data" {...props}>
      <pre>{json}</pre>
      {error && <Text color={'error.700'}>{error}</Text>}
    </Panel>
  )
}

const MicrophoneStuff = () => {
  const { isSpeaking } = useSpeakingDetection()
  const [micError, setMicError] = useState<string | null>(null)

  return (
    <Panel title="Microphone">
      <MicrophonePicker setError={setMicError} />
      <Text>
        You are&nbsp;
        {isSpeaking ? (
          <Text fontWeight={'bold'} color={'success.700'}>
            speaking
          </Text>
        ) : (
          <Text>not speaking</Text>
        )}
        {micError && <Text color={'error.700'}>{micError}</Text>}
      </Text>
    </Panel>
  )
}

const CameraStuff = () => {
  const [cameraError, setCameraError] = useState<string | null>(null)
  return (
    <Panel title="Camera">
      <CameraPicker setError={setCameraError} />
      {cameraError && <Text color={'error.700'}>{cameraError}</Text>}
    </Panel>
  )
}

const Controls = () => {
  return (
    <Panel title="Controls">
      <HStack space={4}>
        <MuteButton />
        <CameraButton />
        <AliasPicker />
      </HStack>
    </Panel>
  )
}

const ControlButton = ({ icon, onPress }) => {
  return (
    <IconButton
      icon={<FontAwesomeIcon icon={icon} size={24} />}
      onPress={onPress}
    />
  )
}

const MuteButton = () => {
  const { room, mute, unmute } = useRoomContext()
  const [isMuted, setIsMuted] = useState<boolean>(false)

  useEffect(() => {
    setIsMuted(room?.me.microphone !== true)
  }, [room?.me.microphone])

  return (
    <ControlButton
      icon={isMuted ? 'fa-microphone-slash' : 'fa-microphone'}
      onPress={() => (isMuted ? unmute() : mute())}
    />
  )
}

const CameraButton = () => {
  const { room, cameraOff, cameraOn } = useRoomContext()
  const [isCameraOn, setIsCameraOn] = useState<boolean>(false)

  useEffect(() => {
    setIsCameraOn(room?.me.camera === true)
  }, [room?.me.camera])

  return (
    <ControlButton
      icon={isCameraOn ? 'fa-regular fa-video' : 'fa-regular fa-video-slash'}
      onPress={() => (isCameraOn ? cameraOff() : cameraOn())}
    />
  )
}

const AliasPicker = () => {
  const { room, chooseAlias } = useRoomContext()
  const [alias, setAlias] = useState<string>(room?.me.alias || '')

  useEffect(() => {
    setAlias(room?.me.alias || '')
  }, [room?.me.alias])

  return (
    <InputGroup size={'sm'}>
      <InputLeftAddon children={'Alias'} />
      <Input value={alias} onChangeText={chooseAlias} />
    </InputGroup>
  )
}

const ParticipantList = () => {
  const { room } = useRoomContext()

  return (
    <Panel title={'Participants'}>
      {room?.attendees
        .filter((attendee) => attendee.connected)
        .map((attendee) => (
          <Participant key={attendee.id} participant={attendee} />
        ))}
    </Panel>
  )
}

const Participant = ({ participant }) => {
  return (
    <HStack space={8}>
      <Text width={'80px'}>
        {participant.alias || participant.id.slice(0, 13) + '...'}
      </Text>
      <FontAwesomeIcon
        icon={participant.microphone ? 'microphone' : 'microphone-slash'}
        size={14}
      />
      <FontAwesomeIcon
        icon={
          participant.camera
            ? 'fa-regular fa-video'
            : 'fa-regular fa-video-slash'
        }
        size={14}
      />
      {participant.speaking && <FontAwesomeIcon icon={'waveform'} size={14} />}
      {participant.typing && <FontAwesomeIcon icon={'keyboard'} size={14} />}
    </HStack>
  )
}

const Audio = () => {
  const { meeting } = useMeetingContext()

  useEffect(() => {
    meeting?.onNewAudio((audio) => {
      audio.play()
    })
  }, [meeting])

  return <></>
}

const Videos = () => {
  const { meeting } = useMeetingContext()
  const videoContainer = useRef<HTMLElement>(null)

  useEffect(() => {
    if (!meeting) return

    meeting.onNewVideo((video) => {
      const element = document.createElement('video')
      element.autoplay = true
      element.playsInline = true
      element.height = 200
      element.width = 200
      video.play(element)
      videoContainer.current.appendChild(element)
    })

    meeting.onVideoRemoved((video) => video.stop())
  }, [meeting])

  return (
    <Panel title="Video">
      <HStack ref={videoContainer} space={4} />
    </Panel>
  )
}

export default secureScreen(RoomTestScreen)
