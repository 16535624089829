import { Box, HStack, Select, Spinner, Text, VStack } from 'native-base'
import { useEffect, useState } from 'react'

import {
  Microphone,
  NO_MICROPHONE,
} from '../../../../domain/models/interfaces/microphone'
import { useMicrophonesContext } from '../../providers/MicrophoneProvider'
import FaIcon from '../_shared/FaIcon'
import MicrophoneLevel from './MicrophoneLevel'

const MicrophonePicker = ({ setError }) => {
  const { listMicrophones, selectMicrophone, selectedMicrophone } =
    useMicrophonesContext()
  const [microphones, setMicrophones] = useState<Microphone[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const fetchMicrophones = async () => {
    setLoading(true)
    setError(undefined)

    try {
      const availableMicrophones = await listMicrophones()
      setMicrophones(availableMicrophones)
    } catch (error) {
      setMicrophones([NO_MICROPHONE])
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchMicrophones()
  }, [])

  const handleSelect = (selectedName: string) => {
    const selectedMicrophone = microphones.find(
      (microphone) => microphone.name === selectedName
    )
    if (selectedMicrophone) selectMicrophone(selectedMicrophone)
  }

  const spinner = (
    <HStack space={4} height={8} alignItems={'center'}>
      <Spinner />
      <Text>Loading microphones...</Text>
    </HStack>
  )

  const picker = (
    <VStack space={4}>
      <HStack alignItems="center" justifyContent="space-between">
        <Select
          selectedValue={selectedMicrophone?.name || ''}
          accessibilityLabel="Select Microphone"
          placeholder="Select Microphone"
          size="lg"
          onValueChange={handleSelect}>
          {microphones.map((mic) => (
            <Select.Item key={mic.name} label={mic.name} value={mic.name} />
          ))}
        </Select>
        <FaIcon name="rotate" onPress={() => fetchMicrophones()} />
      </HStack>
      <MicrophoneLevel />
    </VStack>
  )

  return <Box>{loading ? spinner : picker}</Box>
}

export default MicrophonePicker
