import { Camera, NO_CAMERA } from '../../../domain/models/interfaces/camera'
import { UseCameras } from './useCameras'

const requestPermissions: () => Promise<void> = async () => {
  await navigator.mediaDevices.getUserMedia({ video: true })
}

const fetchCameras = async (): Promise<Camera[]> => {
  const devices = await navigator.mediaDevices.enumerateDevices()
  const videoDevices = devices.filter((device) => device.kind === 'videoinput')
  const cameras = videoDevices.map((device) => ({
    name: device.label || 'Unnamed camera',
    object: device,
  }))
  return cameras
}

export const useCameras: UseCameras = () => {
  const listCameras = async () => {
    await requestPermissions()
    const cameras = await fetchCameras()
    return [...cameras, NO_CAMERA]
  }

  return {
    listCameras,
  }
}

export default useCameras
